<template>
  <ion-badge
    v-if="!rating"
    slot="end"
    color="primary"
    mode="ios"
    @click="$emit('ratingClicked')"
  >
    Értékeld!
  </ion-badge>
  <div
    v-if="!rating"
    class="pressable"
    @click="$emit('ratingClicked')"
    slot="end"
  >
  </div>
</template>

<script>
import {
  IonBadge
} from '@ionic/vue'

export default {
  name: 'RatedBadge',
  props: [
    'rating'
  ],
  components: {
    IonBadge
  }
}
</script>

<style scoped>
.pressable{
  width : 80px;
  height: 45px;
  margin-left: -70px;
  margin-right: -15px;
}
</style>

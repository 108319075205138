<template>
  <ion-item
    lines="none"
    class="transparent"
    button
    @click="goToWineryDetails()"
    detail="false"
  >
  <!--  detailIcon="checkmark-done" -->
    <RatedBadge
      :rating="rating"
      @ratingClicked="ratingClicked"
      class="rated-badge-decale"
    />
    <ion-thumbnail slot="start">
      <div class="circleWhite">
        <span class="helper"></span><img v-if="winery.Logo" :src="winery.Logo.url" class="circleLogo" />
      </div>
    </ion-thumbnail>
    <ion-label>
      <h3>{{ winery.Name }}</h3>
      <h4>
        <span
        class="regionTitle"
        v-for="region in winery.Wineregions"
        :key="region.id"
        >
          {{ region.Title }}
        </span>
      </h4>
      <h4 class="primary">
        <HungarianDate
          :originalDate="checkinDate"
        />
      </h4>
    </ion-label>
  </ion-item>
</template>

<script>
import {
  IonItem, IonLabel, IonThumbnail
} from '@ionic/vue'
import HungarianDate from '@/components/HungarianDate.vue'
import RatedBadge from '@/components/RatedBadge.vue'
import { mapActions } from 'vuex'

export default {
  name: 'WineryCardSmall',
  data () {
    return {
      ratingClickedActive: false
    }
  },
  props: [
    'winery',
    'checkinDate',
    'rating',
    'checkinId'
  ],
  components: {
    IonItem, IonLabel, IonThumbnail,
    HungarianDate, RatedBadge
  },
  methods: {
    ...mapActions('wineries', {
      setWineryInMemory: 'setWineryInMemory',
      setFromPage: 'setFromPage'
    }),
    goToWineryDetails () {
      if (!this.ratingClickedActive) {
        this.$router.push({
          name: 'boraszat-profil',
          params: {
            id: this.winery.id
          }
        })
      } else {
        this.ratingClickedActive = false
      }
    },
    ratingClicked () {
      this.ratingClickedActive = true
      let winerySaved = this.winery
      // add checkinId to winery object, then save it in Vuex
      winerySaved.checkinId = this.checkinId
      this.setWineryInMemory(winerySaved)
      this.setFromPage('boraszatok')
      this.$router.push('/app/rating-stars')
    }
  }
}
</script>

<style scoped>
h3{
  font-family: Alphabet;
  font-size: 16px !important;
}
ion-label{
  color: var(--ion-text-color);
}
.primary{
  color: var(--ion-color-primary);
}
.rated-badge-decale{
  margin-right: 9px;
}
</style>

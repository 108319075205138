<template>
  <div>
    <div
      v-if="winery.Logo && winery.Logo.url"
      class="logoTop"
    >
      <ion-item
        lines="none"
        class="transparent realTransparent"
        detail="false"
      >
        <ion-thumbnail slot="start">
          <div class="circleWhite">
            <span class="helper"></span><img :src="winery.Logo.url" class="circleLogo"/>
          </div>
        </ion-thumbnail>
        <div class="spacer"></div>
      </ion-item>
    </div>
    <img
      v-if="winery.Medias && winery.Medias.length > 0"
      :src="winery.Medias[0].url"
    />
    <img v-else src="assets/images/obt-default-illustration.png" />

    <ion-card-header>
      <ion-card-subtitle
        v-if="checkinDate"
        class="primary"
      >
        <HungarianDate
          :originalDate="checkinDate"
        />
      </ion-card-subtitle>
      <ion-card-title>
        {{ winery.Name }}
      </ion-card-title>
    </ion-card-header>

    <ion-item v-if="winery.WineryProfile" lines="none">
      <ion-label>
        <h4>
          <span
            class="regionTitle"
            v-for="region in winery.Wineregions"
            :key="region.id"
          >
            {{ region.Title }}
          </span>
          <span
            v-if="winery.WineryProfile"
            class="separator"
          >
            •
          </span>
          <span
            v-if="winery.WineryProfile"
            :class="winery.WineryProfile.Title"
          >
            {{ winery.WineryProfile.Title }}
          </span>
        </h4>
      </ion-label>
    </ion-item>
  </div>
</template>

<script>
import {
  IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem, IonThumbnail, IonLabel
} from '@ionic/vue'
import HungarianDate from '@/components/HungarianDate.vue'

export default {
  name: 'WineryCardLargeIllustration',
  props: [
    'winery',
    'checkinDate'
  ],
  components: {
    IonCardHeader, IonCardSubtitle, IonCardTitle, IonItem, IonThumbnail, IonLabel,
    HungarianDate
  }
}
</script>

<style scoped>
ion-card-title{
  font-family: Alphabet;
  font-size: 21px !important;
  color: var(--ion-text-color));
}
ion-card-subtitle{
  font-weight: normal;
}
ion-card-header{
  padding-bottom: 0px;
}
.logoTop{
  float: left;
  height: 0px;
  margin-left: -10px;
}
.spacer{
  height: 70px;
}
ion-item.realTransparent{
  --background: transparent !important;
}
.primary{
  color: var(--ion-color-primary);
}
</style>

<template>
  <master-layout>

    <h2>Itt jártál legutóbb</h2>
    <div
      v-for="checkIn in wineriesCheckedIn"
      :key="checkIn.id"
    >
      <WineryCardLarge
        v-if="wineriesCheckedIn.indexOf(checkIn) == 0"
        :winery="checkIn.Winery"
        :checkinDate="checkIn.createdAt"
        :rating="checkIn.Rating"
        :checkinId="checkIn.id"
        :showButton="'yes'"
      />
      <WineryCardSmall
        v-else
        :winery="checkIn.Winery"
        :checkinDate="checkIn.createdAt"
        :rating="checkIn.Rating"
        :checkinId="checkIn.id"
      />
    </div>
    <div class="bottomSpacer"></div>

  </master-layout>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import WineryCardLarge from '@/components/WineryCardLarge.vue'
import WineryCardSmall from '@/components/WineryCardSmall.vue'
import store from '../store/index'

export default  {
  name: 'Wineries',
  components: {
    WineryCardLarge, WineryCardSmall
  },
  data () {
    return {
      wineriesCheckedIn: []
    }
  },
  computed: {
    ...mapGetters('auth', {
      authData: 'getAuthData'
    }),
    ...mapGetters('wineries', {
      myWineriesChanged: 'getMyWineriesChanged'
    }),
    userAuthData () {
      return this.authData
    }
  },
  async beforeRouteEnter(to, from, next) {
    console.log(to + from + next)
    let authData = store.getters['auth/getAuthData']
    let wineriesZero = store.getters['wineries/getMyWineriesZero']

    if (wineriesZero == null) { // it's the first time here
      const checkIns = await axios.get(process.env.VUE_APP_STRAPI_URI + 'checkins?User=' + authData.userId)
      const wineriesCheckedIn = checkIns.data
      if (wineriesCheckedIn.length == 0) { // no checked-in wineries yet
        store.dispatch('wineries/setMyWineriesZero', true)
        return next({ name: 'elso-checkin' })
      } else {
        store.dispatch('wineries/setMyWineriesZero', false)
        store.dispatch('wineries/setMyWineriesChanged', true)
        return next()
      }
    } else if (wineriesZero == true) {
      return next({ name: 'elso-checkin' })
    } else {
      return next()
    }
  },
  ionViewDidEnter() {
    if(this.myWineriesChanged != false){
      this.showCheckedInWineries()
    }
  },
  methods: {
    ...mapActions('wineries', {
      setMyWineriesChanged: 'setMyWineriesChanged'
    }),
    async showCheckedInWineries () {
      if (this.userAuthData.token) {
        this.wineriesCheckedIn = [] // initialize it
        const checkIns = await axios.get(process.env.VUE_APP_STRAPI_URI + 'checkins?User=' + this.userAuthData.userId + '&_sort=createdAt:DESC')
        this.wineriesCheckedIn = checkIns.data
      }
      this.setMyWineriesChanged(false)
    }
  }
}
</script>

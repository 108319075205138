<template>
  <ion-card
    button
    @click="goToWineryDetails()"
  >
  <WineryCardLargeIllustration
    :winery="winery"
    :checkinDate="checkinDate"
  />
  <ion-item
    lines="none"
    class="transparent realTransparent"
    detail="false"
  >
    <RatedBadge
      v-if="showButton=='yes'"
      :rating="rating"
      @ratingClicked="ratingClicked"
    />
  </ion-item>

  </ion-card>
</template>

<script>
import {
  IonCard, IonItem
} from '@ionic/vue'
import WineryCardLargeIllustration from '@/components/WineryCardLargeIllustration.vue'
import RatedBadge from '@/components/RatedBadge.vue'
import { mapActions } from 'vuex'

export default {
  name: 'WineryCardLarge',
  data () {
    return {
      ratingClickedActive: false
    }
  },
  props: [
    'winery',
    'checkinDate',
    'rating',
    'checkinId',
    'showButton'
  ],
  components: {
    IonCard, IonItem,
    WineryCardLargeIllustration, RatedBadge
  },
  methods: {
    ...mapActions('wineries', {
      setWineryInMemory: 'setWineryInMemory',
      setFromPage: 'setFromPage'
    }),
    goToWineryDetails () {
      if (!this.ratingClickedActive) {
        this.$router.push({
          name: 'boraszat-profil',
          params: {
            id: this.winery.id
          }
        })
      } else {
        this.ratingClickedActive = false
      }
    },
    ratingClicked () {
      this.ratingClickedActive = true
      let winerySaved = this.winery
      // add checkinId to winery object, then save it in Vuex
      winerySaved.checkinId = this.checkinId
      this.setWineryInMemory(winerySaved)
      this.setFromPage('boraszatok')
      this.$router.push('/app/rating-stars')
    }
  }
}
</script>

<style scoped>
ion-item.realTransparent{
  --background: transparent !important;
  margin-top: -45px;
}
</style>
